<template>
    <div>
        <v-dialog
            v-model="showDialogPermitFee"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Update Permit Fee
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Permit fee</label>
                            <v-text-field
                                v-model="permitFee" 
                                suffix=" %"
                                v-formatMoney="percFormat"
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            setPermitFee: {
                default: null
            },

			showDialogPermitFee: {
				default: false
			},
		},

        watch: {
            async "setPermitFee"() {
				if (this.setPermitFee != null && this.setPermitFee != undefined) {
                    this.permitFee = this.setPermitFee;
                }
            },
        },
		
        data: () => ({
			
            permitFee: 0,

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            validations: {
                required: required
            },
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogPermitFee', false);
            },

            async confirm() {

                const permitFee = formaterDecimalBRServer(this.permitFee);

                if (this.permitFee <= 0) {
                    this.showToast("error", "Error!", "Please enter a value for the permit fee!");
                }
                else {
                    const request = {
                        idService: this.id,
                        permitFeeRequest: {
                            permitFee
                        }
                    }

                    const result = await this.$store.dispatch("serviceModule/UpdatePermitFee", request);

                    if (result.success) {
                        this.showToast("success", "Success!", "Permit fee updated successfully!");
                    
                        this.$emit('methodConfirmToCall');
                        this.cancel();
                    }
                    this.cancel();
                }
            }
        }
    };
</script>